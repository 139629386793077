import React from "react"

import Layout from "../components/layout.component"
import Seo from "../components/seo.component"
import PrivacyPolicy from "../components/privacy-policy.component"

const PrivacyPolicyPage = () => {

  return (
    <Layout>
      <Seo title="DanParasky.com Privacy Policy" />
      <section className="hero is-small is-dark">
        <div className="container">
          <div className="hero-body">
            <h1 className="title is-size-2 is-size-1-desktop has-text-white is-uppercase has-text-centered">
              Privacy Policy
            </h1>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="columns pt-8">
            <div className="column is-one-fifth">
              <aside class="menu">
                <p class="menu-label">
                  Policy Summary
                </p>
                <ul class="menu-list">
                  <li><a href="#purposes_data">Purposes</a></li>
                  <li><a href="#contact_information">Contact Info</a></li>
                </ul>
                <p class="menu-label">
                  Full Policy
                </p>
                <ul class="menu-list">
                  <li><a href="#owner_of_the_data">Owner</a></li>
                  <li><a href="#types_of_data">Types of Data</a></li>
                  <li><a href="#place_of_processing">Data Processing</a></li>
                  <li><a href="#use_collected_data">Use of Data</a></li>
                  <li><a href="#data_processing_detailed_info">Personal Data</a></li>
                  <li><a href="#rights_subjects">User Rights</a></li>
                  <li><a href="#cookie_policy">Cookie Policy</a></li>
                  <li><a href="#further_data_processing_info">Additional Info</a></li>
                  <li><a href="#california_info">California Info</a></li>
                </ul>
              </aside>
            </div>
            <div className="column is-four-fifths">
              <div style={{ minHeight: '50vh' }}>
                <PrivacyPolicy />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PrivacyPolicyPage
